<template>
  <div style="color: #000;height: 100%;background: #f5f5f5;">
    <van-nav-bar title="我的订单" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="shoutop">
      <input type="text" v-model="search" placeholder="检测站名称关键字" class="left guan" />
      <div class="right shousuo" @click="onLoad">搜索</div>
    </div>
    <div style="text-align: center;background: #f5f5f5;" class="tophes">
      <!-- <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      > -->
        <div class="mian" @click="xiangqing(item.id)" v-for="(item,idx) in list" :key="idx">
          <div class="mian_a">
            <span class="left">{{item.carNo}}</span>
            <span class="right" style="color: red;" v-if="item.status==0">待支付</span>
            <span class="right" style="color: rgb(13, 144, 110)" v-else>已支付</span>
          </div>
          <div class="mian_b">
            类型：
            <span v-if="item.type == 0">上线验车</span>
            <span v-else>免检领标</span>
            </div>
          <div class="mian_b" v-if="item.type == 0">预约站：{{item.companyName}}</div>
          <div class="mian_b" v-if="item.type == 0">预约时：{{item.appointDate}}</div>
          <div class="heng"></div>
          <div class="mian_b" style="margin-top: 5px;">{{item.createdDate}}</div>
        </div>
        <div style="height: 60px;"></div>
      <!-- </van-list> -->
      <van-empty v-if="list.length < 1" description="暂无该车型数据~" />
    </div>
    <tabbarcar :active="2"></tabbarcar>
  </div>
</template>

<script>
import userModel from "../../api/user";
import { orderlist } from "../../api/check.js";
import tabbarcar from "../../components/tabbarcar.vue";
export default {
  components: {
    tabbarcar
  },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      actv: 1,
      list: [],
      search:'',
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    xiangqing(id) {
      this.$router.push({
        path: "my_order_details",
        query: {
          id: id
        }
      });
    },
    tab(e) {
      this.actv = e;
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        search: this.search
      };
      orderlist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      //   this.$router.go(-1);
      this.$router.push({
        path: "inspect_car"
      });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.shoutop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  line-height: 34px;
  font-size: 14px;
  background: #fff;
  z-index: 9;
  padding: 8px 0;
}
.guan {
  border: 1px solid #dcdcdc;
  margin-left: 12px;
  width: 69%;
  border-radius: 5px;
  padding: 0 5px;
}
.tophes {
  padding-top: 98px;
}

.shousuo {
  width: 18%;
  text-align: center;
  background: #f5f5f5;
  line-height: 35px;
  height: 35px;
  border-radius: 5px;
  font-size: 15px;
  margin-right: 12px;
}
.mian {
  margin: 10px 12px;
  background: #fff;
  border-radius: 5px;
  font-size: 15px;
  padding: 10px 15px;
}
.mian_a {
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 8px;
}
.mian_b {
  text-align: left;
  color: #666;
}
.heng {
  height: 1px;
  width: 100%;
  background: #f5f5f5;
  margin-top: 7px;
}
.shijian {
}
</style>